<template>
	<div class="terms">
		<section class="section bg-gray">
			<div class="container tem">
				<h1>Terms of Us</h1>
				<p>Never use a Bitcoin Address that does not belong to you when purchasing Bitcoin. Always use your own Bitcoin Wallet App Address.</p>
				<p>If someone or a company promises a very high percent ROI per month then it is likely to be a Ponzi Scheme or Scam.</p>
				<p>BitFree Capital does not accept any transactions or refunds from anyone related to MM Global or any similar schemes.</p>
				<p>Do not send Bitcoin to people you never met in person to buy Asia Miles or plane tickets.</p>
				<p>BitFree Capital will not be held responsible for any loss should you fall victim to fraud.</p>
				<p>By agreeing to our Terms of Use you also agree to our Personal Information Collection Statement, Privacy Policy</p>
				<h1>Privacy Policy</h1>
				<p>Please read this Policy carefully before using BitFree Capital Limited ( “Company”), its facilities, services, website and other subjects.The terms “we”, “our” and “us” mean one or more members of the Company, as the context requires.</p>
				<p>This Policy is intended to inform you of our policies and practices in relation to our handling of personal data as well as the kinds of data which we hold and the main purposes for which such data is or is to be used and to whom any data access or correction request should be addressed.</p>

				<p>We may from time to time amend this Policy to ensure that our facilities, services and other</p>

				<p>subjects we offer are complied with the requirements of Personal Data (Privacy) Ordinance and relevant codes of practice which are issued by the Office of Privacy Commissioner for Personal Data and any other legal or regulatory requirements without prior notice. Please approach us or visit our website regularly for the latest Policy. We are committed to ensuring all our employees and any third parties to comply with the strict standard of the relevant data protection.</p>

				<p>The term “you” wherever mentioned in this Policy, mean the data subject(s)” and includes the following categories of individuals:</p>

				<p>i) individual account clients, authorized signatories, beneficiaries and other users of any facilities, services and other subjects and so forth provided by us;</p>

				<p>ii) directors, shareholders, controlling persons, officers, managers, authorized and/or any related key persons of any corporate account clients and users; and</p>

				<p>iii) any users using our facilities, services, websites and other subjects or visiting our office areas; and</p>

				<p>iv) suppliers, contractors, service providers and other contractual counterparties of us.</p>

				<p>The contents of this Policy shall apply to you and form part of any contracts for services that you have or may enter into with us from time to time. If there is any inconsistency between this Policy and the relevant contract, this Policy shall prevail insofar as it relates to the protection of your personal data.</p>

				<p>From time to time, it is necessary for you to supply us with data in connection with the opening or continuation of accounts and the establishment or continuation or provision of facilities, services, and other subjects and visiting our website. Failure to supply relevant data may result in us being unable to open or continue accounts or establish or continue or provide any facilities, services and other subjects.</p>
			</div>
		</section>
	</div>
</template>
<script>
	export default{
		name:'Terms'
	}
</script>
<style lang="scss" scoped>
	.terms{
		padding-top: 64px;
	}
	.tem{
		h1{
			font-size: 26px;
			margin-bottom: 20px;
		}
		p{
			font-size: 14px;
			line-height: 32px;
		}
	}
</style>